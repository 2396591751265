// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/TransformationCarousel.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/TransformationCarousel.tsx");
}
// REMIX HMR END

import { useTranslation } from 'react-i18next';
import { Carousel } from '../Carousel';
import { Text } from '../Text';
import styles from './TransformationCarousel.module.css';
import clsx from 'clsx';
import OptimisedImage from '../OptimisedImage';
export default function TransformationCarousel({
  className
}) {
  _s();
  const {
    t
  } = useTranslation();
  const transformations = t('transformationList', {
    returnObjects: true
  });
  return <Carousel className={clsx(className, styles.transformationCarousel)} itemHeight="31.25rem" itemWidth="17.385rem">
      {transformations.map(transformation => <div key={transformation.name} className={styles.transformationItem}>
          <figure className={styles.transformationBlock}>
            <figcaption className={styles.transformationQuote}>
              <Text size="lg" className={styles.transformationText}>
                {transformation.quote}
              </Text>
              <Text size="lg" weight={600} className={styles.transformationName}>
                {transformation.name}
              </Text>
            </figcaption>
            <div className={styles.transformationImageContainer}>
              <div className={styles.transformationImageWave} />
              <OptimisedImage imageDetails={transformation} className={styles.transformationImage} />
            </div>
          </figure>
        </div>)}
    </Carousel>;
}
_s(TransformationCarousel, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = TransformationCarousel;
var _c;
$RefreshReg$(_c, "TransformationCarousel");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;