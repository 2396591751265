// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/icons/WarningIcon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/icons/WarningIcon.tsx");
  import.meta.hot.lastModified = "1697530893611.9436";
}
// REMIX HMR END

export function WarningIcon({
  className
}) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.556 21.926a9.39 9.39 0 1 0 0-18.779 9.39 9.39 0 0 0 0 18.78Zm0 2.167c6.383 0 11.557-5.174 11.557-11.556C23.113 6.154 17.939.98 11.556.98 5.174.98 0 6.154 0 12.537c0 6.382 5.174 11.556 11.556 11.556Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.562 6.18c.638 0 1.155.518 1.155 1.156v6.934a1.156 1.156 0 0 1-2.31 0V7.336c0-.638.517-1.156 1.155-1.156ZM10.406 17.735c0-.638.519-1.155 1.157-1.154h.034a1.156 1.156 0 0 1-.002 2.311h-.034a1.155 1.155 0 0 1-1.155-1.157Z" />
    </svg>;
}
_c = WarningIcon;
var _c;
$RefreshReg$(_c, "WarningIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;