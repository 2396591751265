// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Carousel.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Carousel.tsx");
}
// REMIX HMR END

import { Children } from 'react';
import styles from './Carousel.module.css';
import clsx from 'clsx';
export function Carousel({
  itemWidth,
  itemHeight,
  className,
  children
}) {
  const reactNodes = Children.toArray(children);
  return <ul className={clsx(className, styles.carousel)} style={{
    ...(itemWidth ? {
      '--item-width': itemWidth
    } : {}),
    ...(itemHeight ? {
      '--item-height': itemHeight
    } : {})
  }}>


      {Children.map(reactNodes, child => <li className={styles.carouselItem}>{child}</li>)}
    </ul>;
}
_c = Carousel;
var _c;
$RefreshReg$(_c, "Carousel");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;