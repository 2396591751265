// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
}
// REMIX HMR END

import { FeatureList } from '~/components/index-route/FeatureList';
import { PageTitle } from '~/components/page/PageTitle';
import { Page } from '~/components/page/Page';
import styles from './_index.module.css';
import { useLoaderData } from '@remix-run/react';
import { useSignal } from '@preact/signals-react';
import { ZodIssueCode, z } from 'zod';
import { getSessionStorage } from '~/sessions';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '~/components/Text';
import { useZodValidation } from 'lib/zod-validation';
import { getFieldIsRequiredIssue } from 'lib/validation/required';
import i18n from '~/i18n.server';
import { useEffect } from 'react';
import { logPageView } from 'lib/log-page-view';
import clsx from 'clsx';
import { Divider } from '../components/Divider';
import { PlanList } from '../components/index-route/PlanList';
import { PageWidth } from '../components/page/PageWidth';
import TransformationCarousel from '../components/index-route/TransformationCarousel';
import { PageHeader } from '~/components/pageHeader/PageHeader';
import { AwardsList } from '~/components/index-route/AwardsList';
import FeatureCarousel from '~/components/index-route/FeatureCarousel';
import { PageHeaderBody } from '~/components/pageHeader/PageHeaderBody';
import { PageHeaderTitle } from '~/components/pageHeader/PageHeaderTitle';
import { PageHeaderCopy } from '~/components/pageHeader/PageHeaderCopy';
import { PageHeaderHeading } from '~/components/pageHeader/PageHeaderHeading';
import { environmentVariables } from 'lib/environment-variables';
export async function loader({
  request
}) {
  const {
    getSession,
    jsonWithUpdatedSession
  } = await getSessionStorage(environmentVariables.ENVIRONMENT);
  const session = await getSession(request.headers.get('Cookie'));
  const t = await i18n.getFixedT(request);
  const validationIssues = session.get('validationIssues');
  const awinClickChecksum = new URL(request.url).searchParams.get('awc');
  if (awinClickChecksum) {
    session.set('awinClickChecksum', awinClickChecksum);
  }
  logPageView(request.url);
  return await jsonWithUpdatedSession({
    title: t('common.subscribeToTheBodyCoach'),
    description: t('common.metaDescription'),
    validationIssues,
    url: request.url
  }, session);
}
export async function action({
  request
}) {
  const {
    getSession,
    redirectWithUpdatedSession
  } = await getSessionStorage(environmentVariables.ENVIRONMENT);
  const session = await getSession(request.headers.get('Cookie'));
  try {
    const {
      plan,
      coupon
    } = z.object({
      plan: z.string(),
      coupon: z.string().optional()
    }).parse(Object.fromEntries(await request.formData()));
    session.set('selectedPlanId', plan);
    session.set('coupon', coupon || undefined);
    return await redirectWithUpdatedSession('/details', session);
  } catch (err) {
    session.flash('validationIssues', [getFieldIsRequiredIssue('plan')]);
    return await redirectWithUpdatedSession('/', session);
  }
}
export const meta = ({
  data,
  matches
}) => [{
  title: data?.title
}, {
  name: 'description',
  content: data?.description
}, ...matches.flatMap(match => match.meta ?? [])];
export default function Index() {
  _s();
  const {
    t
  } = useTranslation();
  const {
    validationIssues,
    url
  } = useLoaderData();
  const {
    fieldHasIssue
  } = useZodValidation(validationIssues);
  const selectedPlan = useSignal(undefined);
  const coupon = useSignal(new URL(url).searchParams.get('coupon') ?? '');
  useEffect(() => {
    coupon.value = new URL(url).searchParams.get('coupon') ?? '';
  }, [url]);
  const planHasErrors = !!fieldHasIssue('plan', ZodIssueCode.custom, 'required');
  return <Page className={styles.page}>
      <PageHeader className={styles.header} flourishes={false} coupon={coupon.value}>
        <PageHeaderBody className={styles.pageHeaderBody}>
          <PageHeaderTitle>
            <PageHeaderCopy className={styles.pageHeadingCopy}>
              <PageHeaderHeading className={styles.pageHeaderHeading} title={<Trans t={t} i18nKey="index.title" components={[<br />, <span className={styles.appName} />]} />} />

            </PageHeaderCopy>
          </PageHeaderTitle>
          <div className={styles.awardListContainer}>
            <AwardsList className={styles.awardList} />
          </div>
          <PlanList coupon={coupon} selectedPlan={selectedPlan} hasError={planHasErrors} className={styles.planList} />
          <FeatureCarousel className={styles.featureCarousel} />
        </PageHeaderBody>
      </PageHeader>
      <div className={styles.content}>
        <div className={styles.plansAndFeatures}>
          <PageWidth>
            <div className={styles.plans}>
              <PlanList coupon={coupon} selectedPlan={selectedPlan} hasError={planHasErrors} />
              <div className={styles.featureList}>
                <Text className={clsx(styles.title, styles.featuresTitle)} size="xl">
                  {t('index.whatYoullGet')}
                </Text>
                <FeatureList />
              </div>
            </div>
          </PageWidth>
        </div>
        <Divider className={styles.transformationDivider} />
        <div className={styles.transformationContent}>
          <PageWidth>
            <PageTitle className={clsx(styles.title, styles.transformationTitle)} centred={false}>
              {t('index.transformations')}
            </PageTitle>
            <TransformationCarousel className={styles.transformationCarousel} />
          </PageWidth>
        </div>
      </div>
    </Page>;
}
_s(Index, "DqulqR/naG6iGG8vOenjTtu9eVc=", false, function () {
  return [useTranslation, useLoaderData, useZodValidation, useSignal, useSignal];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;