// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/plans/PlanPrice.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/plans/PlanPrice.tsx");
}
// REMIX HMR END

import { Text } from '../Text';
import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import { amountToLocaleString } from 'lib/stripe/amount-to-locale-string';
import styles from './PlanPrice.module.css';
import { Trans } from 'react-i18next';
export function PlanPrice({
  plan
}) {
  _s();
  const {
    t,
    i18n
  } = usePrefixedTranslation('common');
  const formattedTotal = amountToLocaleString(plan.amount.total, plan.currency, i18n.language);
  const formattedSubtotal = amountToLocaleString(plan.amount.subtotal, plan.currency, i18n.language);
  if (!formattedTotal) {
    return null;
  }
  return <Text size="lg" weight={500} className={styles.pricePlanText}>
      {plan.freeTrialDays ? plan.upfrontPaymentRequired ? <Trans t={t} i18nKey="plan.freeTrialWithTotal" values={{
      days: plan.freeTrialDays,
      total: formattedTotal
    }} components={[<span data-testid="plan-price"></span>]} /> : t('plan.freeTrial', {
      count: plan.freeTrialDays
    }) : <span data-testid="plan-price">{formattedTotal}</span>}


      {plan.amount.discount && plan.upfrontPaymentRequired ? <Text weight={500} as="span" className={styles.wasPrice} data-testid="plan-was-price">
          {' '}
          <s>{formattedSubtotal}</s>
        </Text> : null}
    </Text>;
}
_s(PlanPrice, "3dEaX9VwcQTsutumYNit7tHi3ms=", false, function () {
  return [usePrefixedTranslation];
});
_c = PlanPrice;
var _c;
$RefreshReg$(_c, "PlanPrice");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;